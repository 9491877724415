// Layout
// ========================================================================
body,
#root,
.wrapper {
  height: 100%;
}

// Vertical Menu
// ========================================================================
.vertical-layout .main-menu {
  &.expanded {
    .navigation {
      li:not(.open) {
        & > ul {
          display: block !important;
        }
      }
    }
  }
  .navigation {
    ul {
      li:not(.open) {
        a {
          &:after {
            transform: rotate(0deg) !important;
          }
        }
      }
      a.active {
        font-weight: 500;

        svg {
          fill: $primary;
        }
      }
    }
  }
}

body #root .wrapper .ps__rail-y {
  left: auto !important ;
  right: 1px !important;
}

// Profile Dropdown (Common)
// ========================================================================
.vertical-layout {
  .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav {
    li.dropdown {
      .dropdown-menu {
        top: 3.5rem !important;
      }
      &.dropdown-user {
        .dropdown-menu {
          top: 1rem !important;
        }
      }
    }
  }
}

// Layout BreadCrumbs
// =======================================================================
.content-header {
  .content-header-title {
    font-weight: 500;
    color: $gray-400;
    padding-right: $spacer;
    border-right: 1px solid lighten($gray-600, 10%);
  }
  .content-header-right {
    .dropdown-toggle:after {
      display: none;
    }
  }
}

// Bookmarks search
// ========================================================================
.header-navbar .navbar-container {
  .navbar-nav {
    .autocomplete-container .suggestion-item span {
      line-height: 1.2;
    }
  }
}

// Component Code
// ========================================================================
.card-snippet {
  pre {
    margin-top: 0;
    border-radius: 0.5rem;
  }
}

// Dashboard Analytics
// =======================================================================

.card-congratulations {
  .avatar svg {
    height: 28px !important;
    width: 28px !important;
  }
}

// Card Analytics
// =======================================================================
.avg-session-progress {
  height: 6px;
}

// Block UI
// ========================================================================
.block-ui-container {
  .block-ui-overlay {
    background: rgba($black, 0.6);
  }
}

// React SlideDown
// ========================================================================
.react-slidedown {
  &::-webkit-scrollbar {
    width: 0px;
  }
}

// Router Transitions
// ========================================================================
.animate__animated.animate__zoomIn,
.animate__animated.animate__fadeInLeft {
  --animate-duration: 1s;
}

// App Ecommerce
// ========================================================================
.ecommerce-application {
  .content-wrapper.animate__animated {
    animation: none;
  }
}

// Customizer
// ========================================================================
.customizer {
  .react-select {
    width: 100%;
    max-width: 200px;

    .select__menu {
      z-index: 2;
    }
  }
}

// Media Query to fix navbar dropdown on sm screen
@media (max-width: 768px) {
  .header-navbar .navbar-container .dropdown.show .dropdown-menu {
    right: -2px;
    width: auto;
    left: 0 !important;
  }
}
